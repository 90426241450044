import axios from "../interceptors/interceptors";
import { GetCdoShortName } from "../data/CDO";
import { DefaultLanguageIsoCode } from "../data/languageData";
import { DocAppointmentTypeId } from "../data/docData";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";
 
 
const handleError = (err: any) => {
  return HandleApiErrorMiddleware("GetConsentFormsAPI", err);
};
 

 
const _getConsentFormsApi = async (
  languageIsoCode: string | undefined = undefined,
  templateKeys: string[] | undefined = undefined,
  includeContent = false
) => {
  let route: string = "GetUnsignedPdfs";
  let apiCfg: any = config.api10;
  let url: string = apiCfg.url + route;
  if (apiCfg.code) {
    url += '?code=' + apiCfg.code;
  }
  let delim: string = apiCfg.code? '&': '?';
  url += delim + "getPdf=" + (includeContent ? "content" : "titles");
 
  // default to english if we don't have a language set
    if (!languageIsoCode) {
    languageIsoCode = DefaultLanguageIsoCode;
  }
 
  let cdoShortName = GetCdoShortName();
  let appointmentTypeId: string = DocAppointmentTypeId;    // all forms are stored under this appointmentTypeId
  let params: any = {
    cdo_id: cdoShortName,
    appointmentTypeId,
  };
  if (languageIsoCode) {
    params.lang = languageIsoCode;
  }
 
  let headers: any = {
    "Content-Type": "application/json",
  };
 
  let req: any = {
    method: "POST",
    url: url,
    headers: headers,
    data: JSON.stringify(params),
  };
 
  return axios(req)
    .then((response) => {
      let items: any = response?.data?.pdfForms || [];
 
      // filter by templateKey
      if (templateKeys && templateKeys.length > 0) {
        items = items.filter((v) => v?.key && templateKeys.includes(v.key));
      }
 
      // filter by language code
      if (languageIsoCode) {
        items = items.filter((v) => v?.lang === languageIsoCode);
      }
 
      return items;
    })
    .catch(handleError);
};
 
export const getConsentFormTitlesApi = async (
  languageIsoCode: string | undefined = undefined,
  templateKeys: string[] | undefined = undefined
) => {
  return _getConsentFormsApi(
    languageIsoCode,
    templateKeys,
    false
  );
};
 
export const getConsentFormContentsApi = async (
  languageIsoCode: string | undefined = undefined,
  templateKeys: string[] | undefined = undefined
) => {
  return _getConsentFormsApi(
    languageIsoCode,
    templateKeys,
    true
  );
};
